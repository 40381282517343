<template>
    <div>
        <article v-for="(w, ind) in data" :key="'thesisPhd_'+ind" :class="['angle publication-card', pubclass(w) ]">

            <div class="student-container">
                
                <div class="pub-authors">

                    <div class="students">
                        <div v-for="(s, i) in students(w)" :key="i">



                            <div class="profile-picture" v-if="s.url">
                                <a :href="'/person/' + s.attributes.drupal_internal__nid + '/' +  s.attributes.path.alias.replace('/person/', '')">
                                    <img :src="endPoint + s.url" width="140" height="auto" />
                                </a>
                            </div>
                            
                            
                            <div class="names">
                                <a :href="'/person/' + s.attributes.drupal_internal__nid + '/' +  s.attributes.path.alias.replace('/person/', '')">{{ s.attributes.title }}</a> <template v-if="i !== students(w).length - 1">,</template>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="middle-container">

                <div class="pub-year">
                    <div class="year">{{ w.attributes.field_starting_year }}</div>
                    <div class="year dash" v-if="w.attributes.field_finishing_year_ && w.attributes.field_type != 'Master Projects'">-</div>
                    <div class="year" v-if="w.attributes.field_finishing_year_ && w.attributes.field_type != 'Master Projects'">{{ w.attributes.field_finishing_year_ }}</div>
                </div>

                <h3 class="pub-title title">{{ w.attributes.title }}</h3>

                <div v-if="w.attributes.field_description">
                    <div class="abstract" v-html="w.attributes.field_description.value"></div>
                </div>
            </div>


            <div class="grid-people">
                

                <div class="pub-authors">
                    Supervisors:
                    <div class="names-all">

                        <div>
                            <div v-for="(s, i) in internalSupervisors(w)" :key="i">

                                <div class="names">
                                    <a :href="'/person/' + s.attributes.drupal_internal__nid + '/' +  s.attributes.path.alias.replace('/person/', '')">{{ s.attributes.title }}</a> 
                                    <template v-if="i !== internalSupervisors(w).length - 1 || externalSupervisors(w) != '' ">,&nbsp;</template></div>
                            </div>
                        </div>

                        
                        <div>
                            <div v-for="(s, index) in externalSupervisors(w)" :key="index">
                                <div class="names">{{s}}<template v-if="index !== externalSupervisors(w).length - 1">,&nbsp;</template>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>


                <div class="pub-authors" v-if="fundingStreams(w) != ''">
                    Funding Streams:
                        <div class="names-all">
                            <div v-for="(s, i) in fundingStreams(w)" :key="i">
                                <div class="names">
                                    {{ s }}<template v-if="i !== fundingStreams(w).length - 1">,&nbsp;</template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pub-link" v-if="w.field_single_url">
                    <a :href="w.field_single_url" target="_blank" class="cta-link"> <span>Learn more</span> </a>
                </div>

        </article>
    </div>
</template>
    
<script>
    export default {
        name: 'thesis',
        props: {
            data: Array,
        },
        data: () => {
            return {
           
            }
        },
        methods: {
            pubclass(thesis) {
                return thesis.field_publication_type
            },


            fundingStreams(thesis) {

            let fundingStream = [];

            if( thesis.field_funding_stream ) {

                thesis.field_funding_stream.forEach((s)=>{
                    fundingStream.push(s.attributes.name)
                })

                return fundingStream;
            }
            else return ''

            },


            students(thesis) {

            let students = [];

            if( thesis.field_students ) {
                students.push(thesis.field_students)
                return students;
            }
            else return ''

            },

            externalSupervisors(thesis) {
            
            if( thesis.attributes.field_external_supervisor ) {
                return thesis.attributes.field_external_supervisor;
            }
            else return ''

        },

            internalSupervisors(thesis) {
            
             
           

            let internalSupervisors = [];
            if( thesis.field_internal_supervisor ) {
                thesis.field_internal_supervisor.forEach((s)=>{

                    internalSupervisors.push(s)
                })
                return internalSupervisors;
            }
            else return ''

        },
        },
        computed: {
            endPoint (){
                return `${process.env.VUE_APP_ENDPOINT}`;
            },
        }
    }
</script>
    
<style lang="scss" scoped>
</style>